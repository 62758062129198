import _storage from "./storage";
import _cookie from "./cookie";
var exports = {};
var Storage = _storage;
var cookie = _cookie;
var _storage_support = true;

try {
  window.localStorage.setItem("test", "42");
  window.sessionStorage.setItem("test", "42");
} catch (e) {
  _storage_support = false;
}

if (_storage_support) {
  window.localStorage.removeItem("test");
  window.sessionStorage.removeItem("test");
  exports = {
    localStorage: window.localStorage,
    sessionStorage: window.sessionStorage
  };
} else {
  var lsCookie = cookie.read("localStorage");
  var ls = new Storage(lsCookie ? JSON.parse(lsCookie) : {}, // init
  function (data) {
    // set
    cookie.create("localStorage", JSON.stringify(data), 365);
  }, function () {
    // clear
    cookie.create("localStorage", "", 365);
  });
  var ss = new Storage();
  exports = {
    localStorage: ls,
    sessionStorage: ss
  };
}

export default exports;
export const localStorage = exports.localStorage,
      sessionStorage = exports.sessionStorage;